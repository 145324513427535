// This plugin will provide all the methods for getting data from the settings store
export default {
  methods: {
    getFavourites() {
      return JSON.parse(localStorage.getItem('favourites'));
    },
    setFavourites(favourites) {
      localStorage.setItem('favourites', JSON.stringify(favourites));
    },
    isMarkedAsFavourite(id) {
      const favourites = this.getFavourites();
      if (!favourites) {
        return false;
      }

      // eslint-disable-next-line
      const result = favourites.some((fav) => fav === id);
      return result;
    },
    addToFavourite(id) {
      const favourites = this.getFavourites();
      favourites.push(id);
      this.setFavourites(favourites);
    },
    removeFromFavourite(id) {
      const favourites = this.getFavourites();
      favourites.splice(favourites.indexOf(id), 1);
      this.setFavourites(favourites);
    },
    initLocalStorage() {
      // check if favourite exist
      const favourites = this.getFavourites();
      if (favourites) {
        // do something with that array
        // init the store and populate it
      } else {
        localStorage.setItem('favourites', JSON.stringify([]));
      }

      // check if clap exist
      const clap = this.getclap();
      if (clap) {
        // do something with that array
        // init the store and populate it
      } else {
        localStorage.setItem('clap', JSON.stringify([]));
      }
    },
    // CLAP -------
    getclap() {
      return JSON.parse(localStorage.getItem('clap'));
    },
    setclap(clap) {
      localStorage.setItem('clap', JSON.stringify(clap));
    },
    isMarkedAsclap(id) {
      const clap = this.getclap();
      if (!clap) {
        return false;
      }

      // eslint-disable-next-line
      const result = clap.some((fav) => fav === id);
      return result;
    },
    addToclap(id) {
      const clap = this.getclap();
      clap.push(id);
      this.setclap(clap);
    },
    removeFromclap(id) {
      const clap = this.getclap();
      clap.splice(clap.indexOf(id), 1);
      this.setclap(clap);
    }
  }
};
