
// @group elements

export default {
  props: {
    tag: {
      type: String,
      default: 'h1'
    },
    text: {
      type: [String, Object],
      default: null
    }
  },

  data() {
    return {
      className: null
    };
  },

  mounted() {
    this.className = this.$el.className ? null : this.tag;
  }
};
