/**
 * TODO: write description
 */
export default {
  methods: {
    getPageBaseUrl(isHome, url, __i18n_lang) {
      const baseUrl = this.$config.baseURL;
      const appLocales = this.$i18n.locales;
      const locale = appLocales.find((l) => __i18n_lang === l.iso);
      const isDefaultLocale = this.$i18n.defaultLocale === locale.code;

      // Note: for base Language, we have this workaround
      // to avoid having Url like 'http://localhost:3333//'
      const pageLocaleSlug = isDefaultLocale ? '' : `/${locale.code}`;
      const pageSlug = isHome ? '' : '/' + (url?.fullPath || '');

      return `${baseUrl}${pageLocaleSlug}${pageSlug}`;
    }
  }
};
