export default {
  methods: {
    $convertDateToString(date, locale = 'de') {
      const toParse = new Date(date);
      const day = toParse.getDate();
      const month = toParse.getMonth() + 1;
      const year = toParse.getFullYear();
      return `${day}.${month}.${year}`;
    }
  }
};
