export default {
  methods: {
    getPrefetchTags() {
      const baseUrl = this.$config.baseURL;
      const { projectId } = this.$config.api;

      const prefetchTags = [
        { hid: 'preconnect', rel: 'preconnect', href: baseUrl },
        { hid: 'dns-prefetch', rel: 'dns-prefetch', href: baseUrl }
      ];

      if (projectId) {
        prefetchTags.push(
          {
            hid: 'preconnect-sanity',
            rel: 'preconnect',
            href: `https://${projectId}.apicdn.sanity.io`
          },
          {
            hid: 'dns-prefetch-sanity',
            rel: 'dns-prefetch',
            href: `https://${projectId}.apicdn.sanity.io`
          }
        );
      }

      return prefetchTags;
    }
  }
};
