import getNavigationQuery from '~/queries/getNavigationQuery';

export const state = () => ({
  menus: [],
  locale: ''
});

export const mutations = {
  setMenus(state, menus) {
    state.menus = menus;
  },
  setLocale(state, locale) {
    state.locale = locale;
  }
};

export const getters = {
  menus(state) {
    return state.menus;
  },
  locale(state) {
    return state.locale;
  },
  getMenuById: (state) => (menuId) => {
    return state.menus.find((menu) => menu.menuId === menuId);
  }
};

export const actions = {
  async load({ commit, state }) {
    const locale = state.locale;
    const navigationQuery = getNavigationQuery(locale);
    const response = await this.$sanity.fetch(navigationQuery);
    commit('setMenus', response ?? []);
  }
};
