
import { mapGetters } from 'vuex';

export default {
  name: 'MenuOverlay',
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive'
    }),

    localePrefix() {
      return this.$i18n.locale === 'de' ? '' : `/${this.$i18n.locale}`;
    }
  },

  watch: {
    isOverlayActive() {
      document.documentElement.classList[this.isOverlayActive ? 'add' : 'remove']('no-scroll');
    },

    $route() {
      this.closeOverlay();
    }
  },

  beforeMount() {
    document.addEventListener('keyup', this.closeOverlayEsc);
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.closeOverlayEsc);
  },

  methods: {
    closeOverlay() {
      this.$store.commit('ui/toggleOverlay', false);
    },

    closeOverlayEsc(e) {
      if (this.active && e.keyCode === 27) {
        this.$store.commit('ui/toggleOverlay', false);
      }
    }
  }
};
