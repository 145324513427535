
// @group page
export default {
  props: {
    tag: {
      type: String,
      default: 'section'
    }
  }
};
