
export default {
  props: {
    menuId: {
      type: String,
      default: 'header'
    }
  },
  computed: {
    navigationTree() {
      const menu = this.getMenuById(this.menuId);
      const navigationTree = this.buildNavigationTree(menu);
      return navigationTree;
    }
  }
};
