// import tailwindConfig from '../tailwind.config.js';
// import resolveConfig from '~/node_modules/tailwindcss/resolveConfig';
/**
 * Function used by postcss that create a linear interpolation of size for the fonts
 * between 2 values. If min and max values are not defined it use the sm and 2xl tailwind config
 * values. If maxFontSize is not defined, it define maxFontSize = minFontSize
 * @param  {Number} $minFontSize Minimum font size (SM)
 * @param  {Number} $maxFontSize Maximum font size (2xl)
 * @param  {Number} $minVw Screen width in px at which the interpolation start, can be undefined
 * @param  {Number} $maxVw Screen width in px at which the interpolation end, can be undefined
 * @return {String} A css clamp function
 */
export default function fluid($minFontSize, $maxFontSize, $minVw, $maxVw) {
  // let fullConfig;

  // We do the fullConfig initilization that way to avoid
  // error if called from the tailwind config file
  // if ($minVw === undefined || $maxVw === undefined) {
  //   fullConfig = resolveConfig(tailwindConfig);
  // }
  if ($minVw === undefined) {
    $minVw = 640;
    // $minVw = fullConfig.theme.screens.sm.replace(/\D/g, '');
  }
  if ($maxVw === undefined) {
    $maxVw = 1536;
    // $maxVw = fullConfig.theme.screens['2xl'].replace(/\D/g, '');
  }

  if ($minFontSize !== undefined && $maxFontSize === undefined) {
    $maxFontSize = $minFontSize;
  }

  const rise = $maxFontSize - $minFontSize;
  const run = $maxVw - $minVw;
  const slope = rise / run;
  const fluidValue = `calc(${slope} * (100vw - ${$minVw}px) + ${$minFontSize}px)`;

  const minFontSizeInRem = $minFontSize / 16;
  const maxFontSizeInRem = $maxFontSize / 16;
  return `clamp(${minFontSizeInRem}rem,${fluidValue},${maxFontSizeInRem}rem)`;
}
