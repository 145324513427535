import imageUrlBuilder from '@sanity/image-url';

export default {
  methods: {
    getOgImagePath(sanityImage) {
      const builder = imageUrlBuilder(this.$config.api);
      return sanityImage ? builder.image(sanityImage).url() : `${this.$config.baseURL}/ogImage.jpg`;
    }
  }
};
