export default function () {
  (function (f, u, s, e_, d, e, c, k, n_) {
    try {
      // eslint-disable-next-line
      var cos = document.cookie.split(';');
      // eslint-disable-next-line
      for (var i = 0; i < cos.length; i++) { var co = cos[i].trim().split('='); if (co[0] == 'fdTrackingType' + e) { c = co[1]; } if (co[0] == 'fdEnvId') { k = co[1]; } }
      // eslint-disable-next-line
      f[d] = f[d] || []; window.addEventListener("load", function () { let fdWinLoaded = !0 });
      // eslint-disable-next-line
      var p = u.getElementsByTagName(s)[0]; var sc = u.createElement(s); sc.async = true;
      // eslint-disable-next-line
      sc.src = e_ + '?p=' + e + '&l=' + (d != null ? d : '') + (c != null ? '&c=' + c : '') + (k != null ? '&e=' + k : '') + '&h=' + encodeURIComponent(location.href) + (n_ != null ? '&a=' + encodeURIComponent(n_) : '');
      p.parentNode.insertBefore(sc, p);
    } catch (e) { }
  })(window, document, 'script', 'https://io.fusedeck.net/d/tm.js', null, 'McQuZs2J1G', null, null, null);
};
