import { groq } from '@nuxtjs/sanity';

/**
 * With this query, we get all the content from the Sanity -> Settings
 */

export default function getSiteSettingsQuery(locale) {
  return groq`*[_type=="page" && __i18n_lang=="${locale}"][0] {
    "socialMedia":  *[_type=="socialMedia"][0] { socialMedia },

    "sitemap": *[
      (_type=="page" || _type=="recipe") &&
      __i18n_lang=="${locale}" &&
      !(_id in path("drafts.**"))
    ] { url, _id, __i18n_lang, live },

    "legal": *[
      _type=="legal" &&
      __i18n_lang=="${locale}"
    ][0] { conditions->{url}, imprint->{url}, privacy->{url} },

    "staticText": *[_type=="staticText" && __i18n_lang=="${locale}"] { entry, _id },

    "siteParts": *[_type=="siteParts" && __i18n_lang=="${locale}"][0] { ... }
  }`;
}
