
export default {
  props: {
    children: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    locale() {
      return this.getLocalePrefix();
    }
  }
};
