/*
 * This function will return an Array<InternalLink>
 * We parse the data we get from Sanity, and get the
 * minimum amount of info we need to render the links
 */
export default {
  methods: {
    buildNavigationTree(menu = []) {
      return !menu.navigation
        ? null
        : menu.navigation.map((n) => this.createMenuItem(n))
          .filter((n) => n);
    },
    createMenuItem(menuItem) {
      const isJumplink = !!menuItem?.isJumplink;
      if (isJumplink) {
        return this.createJumplink(menuItem);
      }
      const page = this.getPageByRef(menuItem.pageRef?._ref);
      if (!page.live) { return; }
      const to = menuItem?.pageRef ? page?.url?.pageSlug : '';

      return ({
        to,
        label: menuItem.title,
        hasLink: !!menuItem.pageRef,
        isDropdown: menuItem.children?.length > 0,
        children: menuItem.children?.length > 0 &&
          menuItem.children.map((c) => this.createMenuItem(c))
      });
    },
    createJumplink(menuItem) {
      return ({
        label: menuItem.title,
        isJumplink: !!menuItem?.isJumplink,
        to: !!menuItem?.jumplink.internalLink &&
          this.getPageByRef(menuItem.jumplink.internalLink?._ref)?.url?.fullPath,
        sectionId: menuItem.jumplink.sectionId
      });
    }
  }
};
