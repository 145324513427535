import Vue from 'vue';
import VueFormGenerator from 'vue-form-generator';
import VuePhoneNumberInput from 'vue-phone-number-input';
import FieldPhoneNumber from '../components/elements/FieldPhoneNumber.vue';
// import 'vue-form-generator/dist/vfg.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// eslint-disable-next-line
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// Custom fields
// eslint-disable-next-line
Vue.component('FieldPhoneNumber', FieldPhoneNumber);

Vue.use(VueFormGenerator);
