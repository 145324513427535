export default {
  props: {
    triggering: {
      // The value must match one of these strings
      validator(value) {
        return ['onlyOnce', 'onlyWhenScrollDown', 'always'].includes(value);
      },
      default: 'onlyWhenScrollDown'
    }
  },
  data() {
    return {
      observer: null,
      layoutShiftCompensation: 0
    };
  },
  mounted() {
    // We build an  Array for our Threshold (check why in doc)
    const thresholdArray = (steps) =>
      Array(steps + 1)
        .fill(0)
        .map((_, index) => index / steps || 0);

    const obsOptions = {
      // We want to trigger the callback at each 5% of the element
      threshold: thresholdArray(20)
    };

    // Creation of the observer and the options
    this.observer = new IntersectionObserver(this.inViewport, obsOptions);

    const ELs_inViewport = this.$refs.ref;
    this.observer.observe(ELs_inViewport);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    inViewport(entries) {
      let previousY = 0;
      let previousRatio = 0;

      // We check the scroll directions in order to define the animations triggers
      entries.forEach((entry) => {
        const currentY = entry.boundingClientRect.y;
        const currentRatio = entry.intersectionRatio;
        const isIntersecting = entry.isIntersecting;

        if (currentY < previousY) {
          if (currentRatio > previousRatio && isIntersecting) {
            entry.target.classList.add('animation-trigger');
            if (this.triggering === 'always') {
              entry.target.classList.remove('animation-trigger');
            }
          } else if (this.triggering === 'always') {
            entry.target.classList.remove('animation-trigger');
          }
        } else if (currentY > previousY) {
          if (currentRatio <= previousRatio && !isIntersecting) {
            if (this.triggering === 'onlyOnce' && this.layoutShiftCompensation === 0) {
              // BUG
              // when DOM is loaded the animation class might be added because of layout shifting.
              // To avoid that problem we remove the animation class the first time we go out from
              // the element. However this create a bug if the starting point is under the element
              // because the class won't be removed the first time. So we'll have 2X the animation
              // instead of just 1X time
              entry.target.classList.remove('animation-trigger');
              this.layoutShiftCompensation++;
            } else if (this.triggering !== 'onlyOnce') {
              entry.target.classList.remove('animation-trigger');
            }
          } else {
            entry.target.classList.add('animation-trigger');
          }
        }
        previousY = currentY;
        previousRatio = currentRatio;
      });
    }
  }
};
