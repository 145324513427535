import Vue from 'vue';
import config from '~/starter.config';
import Capitalize from '~/mixins/capitalize';
import GetPrefetchTags from '~/mixins/seo/getPrefetchTags';
import GetPageBaseUrl from '~/mixins/getPageBaseUrl';
import GetOgImagePath from '~/mixins/seo/getOgImagePath';
import GetMultilanguageLinkTags from '~/mixins/seo/getMultilanguageLinkTags';
import GetPageSchema from '~/mixins/seo/getPageSchema';
import GetBaseMetaTags from '~/mixins/seo/getBaseMetaTags';
import GetSocialMediaMetaTags from '~/mixins/seo/getSocialMediaMetaTags';
import BuildNavigationTree from '~/mixins/buildNavigationTree';
import SetDevmode from '~/mixins/setDevmode';
import SetViewportSizes from '~/mixins/setViewportSizes';
import GeneratePageMetaTags from '~/mixins/seo/generatePageMetaTags';
import ScrollTo from '~/mixins/scrollTo';
import GetLocalePrefix from '~/mixins/getLocalePrefix';
import GetSiteSettings from '~/mixins/getSiteSettings';
import GetLocalStorage from '~/mixins/localStorage';
import ConvertDateToString from '~/mixins/convertDateToString';
import BackgroundClassPicker from '~/mixins/backgroundClassPicker';
import consoleLogBOLD from '~/mixins/consoleLogBOLD';
// import IsInViewport from '~/mixins/isInViewport'

Vue.mixin(Capitalize);
Vue.mixin(consoleLogBOLD);
Vue.mixin(GetPrefetchTags);
Vue.mixin(GetPageBaseUrl);
Vue.mixin(GetOgImagePath);
Vue.mixin(GetMultilanguageLinkTags);
Vue.mixin(GetPageSchema);
Vue.mixin(GetSocialMediaMetaTags);
Vue.mixin(GetBaseMetaTags);
Vue.mixin(BuildNavigationTree);
Vue.mixin(GeneratePageMetaTags);
Vue.mixin(ScrollTo);
Vue.mixin(GetLocalePrefix);
Vue.mixin(GetSiteSettings);
Vue.mixin(GetLocalStorage);
Vue.mixin(ConvertDateToString);
Vue.mixin(BackgroundClassPicker);
// Vue.mixin(IsInViewport);
// NOTE: when bootstrapping the project, we should avoid to include not used plugin.
// They affect the final bundle size.

if (config.mixins.setDevmode) {
  Vue.mixin(SetDevmode);
}

if (config.mixins.setViewportSizes) {
  Vue.mixin(SetViewportSizes);
}
