// This plugin will provide all the methods for getting data from the settings store
export default {
  methods: {
    // This method will return a static text
    // based on the provided 'key'
    // Usage in template: $text('your-Sanity-string-key')
    // Usage in methods: this.$text('your-Sanity-string-key')
    $text(key) {
      if (!key) { return; }
      const text = this.$store.getters['settings/getStaticTextByKey'](key);
      return text?.entry.value || `No matching string with ref: ${key}`;
    },
    getMenuById(menuId) {
      if (!menuId) { console.warn(`No ID for menu with title: ${menuId}`); return; }
      const menu = this.$store.getters['navigation/getMenuById'](menuId);
      return menu || `No matching MENU with ID: ${menuId}`;
    },
    getPageByRef(_ref) {
      if (!_ref) { return; }
      const page = this.$store.getters['settings/getSlugByRef'](_ref);
      return page || `No page with ref: ${_ref}`;
    }
  }
};
