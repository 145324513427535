export const state = () => ({
  documentData: '',
  isPreviewMode: false
});

export const mutations = {
  setPreviewMode(state, isPreviewMode) {
    state.isPreviewMode = isPreviewMode;
  },
  setDocumentData(state, documentData) {
    state.documentData = documentData;
  }
};

export const getters = {
  isPreviewMode(state) {
    return state.isPreviewMode;
  },
  documentData(state) {
    return state.documentData;
  }
};
