const sanityClient = require('@sanity/client');

export default async function ({ route, isHMR, $config, store }) {
  try {
    if (isHMR) {
      return;
    }
    const {
      query: { preview, documentId }
    } = route;

    if (!preview) {
      store.commit('preview/setPreviewMode', false);
      return;
    }

    const client = sanityClient({
      projectId: $config.api.projectId,
      dataset: $config.api.dataset,
      apiVersion: $config.api.apiVersion,
      useCdn: false,
      token:
        // eslint-disable-next-line
        'skkD5ICCZO5Hu2nqIy6syuDcNPIh6h1qKr3RERZIW6k3SiifVz292EHYGeRowItHunePppiQobCTeqPwNe4tH2wQvyRgo2WswZlSatOI42rnnnSSnmP0rwmR4uuqPw2IfEKeCeP6vFOxiB2WG85UaOmB2aApIzT6qf77JJX4H5kGJxeHiWV1'
    });

    const query = `*[_id=="${documentId}"][0] {
      content{
        ...,
        sections[]{
          (_type == "detailHero") => {
            "background": picture.refImage->{"image": upload.asset->{url}},
            ...
          },
          ...,
        }
      }
    }`;
    const result = await client.fetch(query);
    store.commit('preview/setPreviewMode', true);
    store.commit('preview/setDocumentData', result || {});
  } catch (e) {
    throw new Error(`previewMode.js: ${e}`);
  }
}
