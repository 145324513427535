
import resolveConfig from 'tailwindcss/resolveConfig';
import { mapGetters } from 'vuex';
import tailwindConfig from '../../tailwind.config.js';
import SVGlogo from '../../assets/svg/echoLogo.svg';

export default {
  name: 'HeaderComponent',
  components: { SVGlogo },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isScrollingDown: false,
      isScrollingUp: true,
      lastScrollYPosition: 0,
      // A scroll will increase Y nearly +- 20px.
      // Even when setting "no-scroll" to html element, will cause a page scroll.
      // To prevent the header bar from going away at the minimum scroll or when opening menu,
      // we set this tollerance that will help to increase the UX;
      tollerance: 10
    };
  },
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive',
      siteParts: 'settings/siteParts'
    }),
    clubButtonUrl() {
      return `/${this.getPageByRef(this.siteParts?.clubButton?.link?._ref)?.url?.fullPath}` || '';
    },
    tryNowButtonUrl() {
      return this.siteParts?.tryNowButton.isExternal
        ? this.siteParts?.tryNowButton.externalLink
        : `/${this.getPageByRef(this.siteParts?.tryNowButton?.link?._ref)?.url?.fullPath}`;
    }
  },
  beforeMount() {
    this.debouncedHandleScroll = this.debounce(this.handleScroll, 15);
    window.addEventListener('scroll', this.debouncedHandleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleNavOverlay() {
      this.$store.commit('ui/toggleOverlay', !this.isOverlayActive);
    },
    debounce(func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          func.apply(context, args);
        }, wait);
      };
    },

    handleScroll() {
      if (window.scrollY <= 0) {
        // Ensure the header is visible at the top of the page for IOS bug
        this.isScrollingDown = false;
        this.isScrollingUp = true;
      } else if (window.scrollY > this.lastScrollYPosition + this.tollerance) {
        if (window.innerWidth <= resolveConfig(tailwindConfig).theme.screens.sm.slice(0, -2)) {
          this.isScrollingDown = true;
          this.isScrollingUp = false;
        }
      } else if (window.scrollY < this.lastScrollYPosition) {
        this.isScrollingDown = false;
        this.isScrollingUp = true;
      }

      this.lastScrollYPosition = window.scrollY;
    }
  }
};
