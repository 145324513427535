const middleware = {}

middleware['getLocaleNavigation'] = require('../middleware/getLocaleNavigation.js')
middleware['getLocaleNavigation'] = middleware['getLocaleNavigation'].default || middleware['getLocaleNavigation']

middleware['layout'] = require('../middleware/layout.js')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['previewMode'] = require('../middleware/previewMode.js')
middleware['previewMode'] = middleware['previewMode'].default || middleware['previewMode']

export default middleware
