export default {
  methods: {
    getPageSchema(pageHref, title, description) {
      return [
        { '@context': 'http://schema.org' },
        { '@type': 'WebPage' },
        { name: title },
        { description },
        { url: pageHref }
      ];
    }
  }
};
