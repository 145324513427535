
import { mapGetters } from 'vuex';
import SVGbuy from '../../assets/svg/buy.svg';
import SVGEchoLogo from '../../assets/svg/echoLogo.svg';
import SVGInstagram from '../../assets/svg/instagram.svg';

export default {
  name: 'FooterComponent',
  // eslint-disable-next-line
  components: { SVGbuy, SVGEchoLogo, SVGInstagram },
  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive',
      siteParts: 'settings/siteParts',
      legal: 'settings/legal',
      socialMedia: 'settings/socialMedia'
    })
  }
};
