/**
 * Middleware feature:
 * - redirect user to home when reaching domain/defaultLocale/...
 * - bootstrap the load actions (navigation and settings data)
 */
export default async function ({ store, route, isHMR, i18n, redirect }) {
  if (isHMR) { return; }

  const { fullPath } = route;
  const prefix = fullPath.split('/').filter((slug) => slug !== '')[0];
  const isMatching = i18n.locales.some((l) => l.code === prefix);

  // Workaround: defaultLocale has no prefix
  const locale = isMatching
    ? i18n.locales.find((locale) => locale.code === prefix).code
    : i18n.defaultLocale;

  // Redirect i18n.defaultLocale default if users / bots try to reach domain/{defaultLocale}/...
  const hasToRedirect = locale === i18n.defaultLocale && prefix === i18n.defaultLocale;

  if (hasToRedirect) {
    const parsedUrl = fullPath.replace(`/${i18n.defaultLocale}`, '');
    const isHome = parsedUrl === '';
    redirect(isHome ? '/' : parsedUrl);
  }

  store.commit('navigation/setLocale', locale);
  // Load static data from Sanity like navigation, static content and sitemap
  await store.dispatch('navigation/load');
  await store.dispatch('settings/load');
}
