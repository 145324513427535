export default {
  methods: {
    getBaseMetaTags(title, description, noIndex, noFollow) {
      // Generate Base meta tag
      const baseMeta = [
        { hid: 'description', name: 'description', content: description },
        { hid: 'title', name: 'title', content: title }
      ];

      // Generate Robots meta tag
      const index = noIndex ? 'noindex, ' : 'index, ';
      const follow = noFollow ? 'nofollow' : 'follow';
      baseMeta.push({ hid: 'robots', name: 'robots', content: `${index}${follow}` });

      return baseMeta;
    }
  }
};
