const plugin = require('tailwindcss/plugin');
const { default: fluid } = require('./plugins/postcss-functions');

const smScreenSize = 640;
const mdScreenSize = 768;
const lgScreenSize = 1024;
const xlScreenSize = 1280;
const xxlScreenSize = 1536;
const xxxlScreenSize = 1850;

module.exports = {
  mode: 'jit',
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: `${smScreenSize}px`,
      // => @media (min-width: 640px) { ... }

      md: `${mdScreenSize}px`,
      // => @media (min-width: 768px) { ... }

      lg: `${lgScreenSize}px`,
      // => @media (min-width: 1024px) { ... }

      xl: `${xlScreenSize}px`,
      // => @media (min-width: 1280px) { ... }

      '2xl': `${xxlScreenSize}px`,
      // => @media (min-width: 1536px) { ... }

      '3xl': `${xxxlScreenSize}px`
      // => @media (min-width: 1850px) { ... }
    },
    fontFamily: {
      // Add fonts
      sans: ['HelveticaNow', 'sans-serif']
    },
    grid: {
      gridXMargin: fluid(24, 56, smScreenSize, xxlScreenSize),
      gutterXDesktop: '48px',
      gutterXMobile: '16px',
      gutterYDesktop: '48px',
      gutterYMobile: '30px'
    },
    spacing: {
      /* These values are to be used for vertical spacing mainly
       By default, these values are inherited by the:
       padding, margin, width, height, maxHeight, gap, inset, space, and translate core plugins. */

      0: '0', // 0px
      1: '0.5rem', // 8px
      2: '1rem', // 16px
      3: '1.5rem', // 24px
      4: '2rem', // 32px
      5: '2.5rem', // 40px
      6: '3rem', // 48px
      7: '3.5rem', // 56px
      8: '4rem', // 64px
      9: '5rem', // 80px
      10: '6rem', // 96px
      11: '7rem', // 112px
      12: '8rem', // 112px
      13: '9rem', // 112px
      14: '10rem', // 112px
      15: '11rem', // 112px
      16: '12rem', // 112px
      17: '13rem', // 112px
      18: '15rem', // 112px
      19: '15rem', // 112px
      20: '16rem' // 112px
    },
    extend: {
      colors: {
        // Add project colors
        main: '#A7A2C2', // Lavande Middle
        secondary: '#B7CD95', // Mint
        lavendeLight: '#A7A2C2', // Lavende,
        lavendeDark: '#595477',
        mintLight: '#DDEBC8',
        mintDark: '#91AC67',
        mintUltraDark: '#6F8252',
        graydark: '#2B2B2B',
        graylight: '#EDEDED'
      }
    }
  },
  variants: {
    extend: {}
  },
  plugins: [
    require('tailwindcss-selection-variant'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
    plugin(function ({ addComponents, theme }) {
      addComponents({
        '.h1': {
          fontSize: fluid(120, 180),
          lineHeight: fluid(103, 160),
          hyphens: 'auto',
          hyphenateLimitChars: '7 2 2',
          fontWeight: '700'
        },
        '.h2': {
          fontSize: fluid(56, 120),
          lineHeight: fluid(53, 120),
          hyphens: 'auto',
          hyphenateLimitChars: '7 2 2',
          fontWeight: '700'
        }
      });
    })
  ],
  corePlugins: {
    container: false
  }
};
